<script>
import { defineComponent } from '@vue/composition-api';
import { SHADES, variants } from '@/lib/variants';

export default defineComponent({
  name: 'Notification',
  props: {
    variant: {
      type: String,
      required: false,
      default: SHADES,
      validator: (value) => Object.values(variants()).includes(value),
    },
  },
  setup() {},
});
</script>

<template>
  <div :class="['notify border rounded-md py-2 px-3', variant]">
    <slot />
  </div>
</template>

<style scoped>
.notify.primary {
  @apply border-primary bg-primary-lighter;
}
.notify.shades {
  @apply border-shades bg-gray-50;
}
.notify.dark {
  @apply border-dark bg-dark-lighter;
}
.notify.accent {
  @apply border-accent bg-accent-lighter;
}
.notify.success {
  @apply border-success bg-success-lighter;
}
.notify.warning {
  @apply border-warning bg-warning-lighter;
}
.notify.danger {
  @apply border-danger bg-danger-lighter;
}
</style>
